#ot-sdk-btn.ot-sdk-show-settings,
#ot-sdk-btn.optanon-show-settings {
  color: rgb(95, 46, 234) !important;
  border: 1px solid rgb(95, 46, 234) !important;
  height: auto;
  white-space: normal;
  word-wrap: break-word;
  padding: 0.8em 2em;
  font-size: 0.8em;
  line-height: 1.2;
  cursor: pointer;
  -moz-transition: 0.1s ease;
  -o-transition: 0.1s ease;
  -webkit-transition: 1s ease;
  transition: 0.1s ease;
  font-family: Poppins, sans-serif, Moderna, Helvetica Neue, Droid Sans, Fira Sans, Cantarell, Ubuntu, Inter, Oxygen, Roboto, Segoe UI, BlinkMacSystemFont, -apple-system;
}

@keyframes ring {
  0% {
    transform: rotate(35deg);
  }

  6.25% {
    transform: rotate(-30deg);
  }

  12.5% {
    transform: rotate(25deg);
  }

  18.75% {
    transform: rotate(-20deg);
  }

  25% {
    transform: rotate(15deg);
  }

  31.25% {
    transform: rotate(-10deg);
  }

  37.5% {
    transform: rotate(5deg);
  }

  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
